import {request} from '@/sujs/http.js'
export function getExamData(data){
	return request({
		url:'/user/exam/my-paging',
		method:"POST",
		data:data
	})
}

export function getXiangQData(data){
	return request({
		url:'/paper/paper/paging',
		method:"POST",
		data:data
	})
}